<template>
  <div class="app-header-nav">
    <div class="user-info">
      <p class="header">
        <RouterLink :to="`/company/${good.id}`">
          <span class="name">{{ good.businessName }}</span>
        </RouterLink>
      </p>

      <Row>
        <Col span="24">
          <span class="col">
            <img :src="require('/static/icons/firmIcon.png')" />
            &nbsp;认证卖家
            <span class="col">联系电话：{{ good.contactPhone }}</span>
          </span>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
// import { useStore } from 'vuex'
// import { watch } from 'vue'
import VueEvent from '@/model/event'
export default {
  name: 'AppGoodsHeaderNav',
  computed: {
    storeMerchantStore () {
      return this.$store.state.good.merchantStore
    },
  },
  data () {
    return {
      value: '',
      good: {},
    }
  },
  methods: {},
  watch: {
    storeMerchantStore (val) {
      // console.log('val:', val)
      VueEvent.emit('currentMsCodeMitt', val.code)
      this.good = val
    },
  },

  created () {},

  destoryed () {
    VueEvent.all.clear()
  },
  setup () {
    // const store = useStore()
    // const good = store.state.good.merchantStore
    // return {
    //   good,
    // }
  },
}
</script>

<style scoped lang="less">
.app-header-nav {
  width: 820px;
  display: flex;
  // justify-content: space-around;
  padding-left: 40px;
  position: relative;
  z-index: 998;
  .user-info {
    width: 70%;
    // margin-left: 20px;
    .name {
      // background: #fff4e3;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      margin-top: 5px;
      border-radius: 4px;
      padding: 2px;
      width: 40px;
      height: 22px;
    }
    .header {
      padding-left: 26px;
      font-size: 18px;
      font-weight: normal;
      color: #333333;
    }
    .col {
      padding-left: 26px;
      font-size: 12px;
      font-weight: normal;
      color: #999999;
    }
  }
}
/deep/ .ivu-input {
  padding-left: 8px;
  border: 2px solid #d12b23;
  height: 36px;
  line-height: 36px;
  width: 100%;
}
.btn {
  // display: block;
  width: 144px;
  background: #d12b23;
  height: 37px;
  font-weight: 450;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: 1px solid #d12b23;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
