<template>
  <header class="app-header">
    <div class="container">
      <h1 class="logo">
        <RouterLink to="/">
          <img src="http://file.qltrade.ixiaoguo.com.cn/file/4255933719577558.png" />
        </RouterLink>
      </h1>
      <AppGoodsHeaderNav />
    </div>

    <div class="line"></div>
  </header>
</template>

<script>
import AppGoodsHeaderNav from '@/components/AppGoodsHeaderNav'

export default {
  name: 'AppHeader',
  components: {
    AppGoodsHeaderNav,
  },

  data () {
    return {}
  },
  created () {},
  methods: {},
}
</script>

<style scoped lang="less">
.app-header {
  background: #fff;

  .container {
    display: flex;
    align-items: center;
    .title {
      // display: inline-block;
      color: #000;
      height: 40px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      //  width: 150px;
      img {
        height: 20px;
      }
    }
  }

  .logo {
    width: 200px;
    img {
      display: block;
      height: 110px;
      width: 100%;
      text-indent: -9999px;
      padding: 10px;
    }
    // a {
    //   display: block;
    //   height: 110px;
    //   width: 100%;
    //   text-indent: -9999px;
    //   background: url(../../static/icons/log.png) no-repeat center 18px /
    //     contain;
    // }
  }
  .search {
    width: 170px;
    height: 32px;
    position: relative;
    border-bottom: 1px solid #e7e7e7;
    line-height: 32px;
    .icon-search {
      font-size: 18px;
      margin-left: 5px;
    }
    input {
      width: 140px;
      padding-left: 5px;
      color: #666;
    }
  }
}

.line {
  height: 4px;
  background: #d12b23;
}
.seamless-warp {
  height: 40px;
  overflow: hidden;
  margin-left: 8px;
  ul {
    display: flex;
    flex-wrap: wrap;
    // padding: 0 5px;
    li {
      display: flex;
      justify-content: center;
      align-items: center;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>
