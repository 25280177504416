<template>
  <div class="goods-tabs">
    <nav style="background: #f8f8fb">
      <div class="goods-detail-tab">商品详情</div>
    </nav>
		<div class="goods-detail">
    <!-- 属性 -->
    <ul class="attrs">
      <li>
        <span class="dt">名称</span>
        <span class="dd">{{goods.productName}}</span>
      </li>
			 <li>
        <span class="dt">材质</span>
        <span class="dd">{{goods.material}}</span>
      </li>
			 <li>
        <span class="dt">规格</span>
        <span class="dd">{{goods.formate}}</span>
      </li>
			<!-- <li>
        <span class="dt">型号</span>
        <span class="dd">{{goods.modelNum}}</span>
      </li> -->
				<li>
        <span class="dt">产地</span>
        <span class="dd">{{goods.sendFromCityCode}}</span>
      </li>
				<!-- <li>
        <span class="dt">生产时间</span>
        <span class="dd">{{goods.createTime}}</span>
      </li> -->
				<li>
        <span class="dt">仓库</span>
        <span class="dd">{{goods.warehouseName}}</span>
      </li>
				<li>
        <span class="dt">备注</span>
        <span class="dd">{{goods.description}}</span>
      </li>
    </ul>
  </div>

  </div>
</template>
<script>
import { ref, inject } from 'vue'


export default {
  name: 'GoodsTab',
	props: {
    goods: {
      type: Object,
      default: () => ({}),
    },
  },

  setup () {
    const activeComponentName = ref('GoodsDetail')
    const goodsDetail = inject('goodsDetail')
    return { activeComponentName, goodsDetail }
  },
}
</script>
<style lang="less" scoped>
.goods-tabs {
  min-height: 300px;
  min-width: 1200px;
  margin-bottom: 80px;
  background: #fff;
  border: 1px solid #cfcdcd;

  .goods-detail-tab {
    background: #d12b23;
    width: 150px;
    // height: 100%;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
  }
  nav {
    height: 70px;
    line-height: 70px;
    display: flex;
    border-bottom: 1px solid #f5f5f5;
    a {
      padding: 0 40px;
      font-size: 18px;
      position: relative;
      > span {
        color: @priceColor;
        font-size: 16px;
        margin-left: 10px;
      }
      &:first-child {
        border-right: 1px solid #f5f5f5;
      }
      &.active {
        &::before {
          content: '';
          position: absolute;
          left: 40px;
          bottom: -1px;
          width: 72px;
          height: 2px;
          background: @xtxColor;
        }
      }
    }
  }
}
.goods-detail {
  padding: 40px;
  .attrs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    li {
      display: flex;
      margin-bottom: 10px;
      margin-left: 20px;
      width: 30%;
      .dt {
        width: 100px;
        color: #999;
      }
      .dd {
        flex: 1;
        color: #666;
      }
    }
  }
  > img {
    width: 100%;
  }
}
</style>
