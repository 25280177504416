<template>
  <p class="g-name">{{ goods.productName }}</p>
  <div class="g-service">
    <dl>
      <dt>价格</dt>
      <dd>
        <div class="money">￥{{ goods.activePrice }}/{{ goods.unitName }}</div>
      </dd>
    </dl>
    <dl>
      <dt>已售</dt>
      <dd>{{(goods.inventory-goods.currentInventory).toFixed(4)}}</dd>
    </dl>
    <dl>
      <dt>剩余库存</dt>
      <dd>
        {{ goods.currentInventory }}
      </dd>
    </dl>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'GoodsInfo',

  props: {
    goods: {
      type: Object,
      default: () => ({}),
    },
  },
  setup () {
    // 存储配送定制
    const location = ref('')
    const cityChanged = (data) => {
      location.value = data.location
    }

    return { location, cityChanged }
  },
}
</script>

<style lang="less" scoped>
.g-name {
  font-size: 18px;
  font-weight: 600;
}
.g-desc {
  color: #999;
  margin-top: 10px;
}
.g-price {
  margin-top: 10px;
  span {
    &::before {
      content: '¥';
      font-size: 14px;
    }
    &:first-child {
      color: @priceColor;
      margin-right: 10px;
      font-size: 22px;
    }
    &:last-child {
      color: #999;
      text-decoration: line-through;
      font-size: 16px;
    }
  }
}
.g-service {
  background: #fef8f8;
  width: 500px;
  padding: 20px 10px 0 10px;
  margin-top: 10px;
  dl {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    dt {
      width: 80px;
      color: #999;
    }
    dd {
      color: #666;
      &:last-child {
        span {
          margin-right: 10px;
          &::before {
            content: '•';
            color: @xtxColor;
            margin-right: 2px;
          }
        }
        a {
          color: @xtxColor;
        }
      }
    }
  }
}
.money {
  color: #d12b23;
  font-size: 24px;
}
</style>
