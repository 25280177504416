<template>
  <div>
  <AppTopNav />
  <AppGoodsHeader/>
  <AppHeaderSticky />
  <slot />
  <AppFooter />
  </div>

</template>
<script>
import AppTopNav from '@/components/AppTopNav'
import AppFooter from '@/components/AppFooter'
import AppGoodsHeader from '@/components/AppGoodsHeader'
import AppHeaderSticky from '@/components/AppHeaderSticky'

export default {
  name: 'AppGoodsLayout',
  components: { AppHeaderSticky, AppGoodsHeader, AppFooter, AppTopNav },
	created () {

  },
  setup () {

  },
}
</script>
<style scoped lang="less">
</style>

