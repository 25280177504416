<template>
  <AppGoodsLayout>
    <div class="xtx-goods-page">
      <div class="container">
        <a>
          <PageHeader title="返回" back hidden-breadcrumb @click="onBack()" />
        </a>

        <!-- 商品信息 -->
        <div class="goods-info">
          <!-- 左侧 -->
          <div class="media">
            <GoodsImages :images="img" />
          </div>
          <!-- 右侧 -->
          <div class="spec">
            <GoodsInfo :goods="goodsDetail" />
            <ul class="attrs">
              <li>
                <span class="dt">品名</span>
                <span class="dd">{{ goodsDetail.productName }}</span>
              </li>
              <li>
                <span class="dt">仓库</span>
                <span class="dd">{{ goodsDetail.warehouseName }}</span>
              </li>
              <li>
                <span class="dt">服务</span>
                <span class="dd">自提/快递</span>
              </li>
              <li>
                <span class="dt">发货地</span>
                <span class="dd">{{ goodsDetail.sendFromCityCode }}</span>
              </li>
              <li>
                <span class="dt">支付方式</span>
                <span class="dd">平台支付</span>
              </li>
              <li>
                <span class="dt">交易模式</span>
                <span class="dd">
                  <div v-if="goodsDetail.tradeType == '1'">全款</div>
                  <div v-else>保证金</div>
                </span>
              </li>

              <!-- <li>
                <span class="dt">全款支付</span>
                <span class="dd">2.00吨 抄码</span>
              </li> -->
            </ul>
            <XtxNumberBox
              v-model="count"
              label="数量"
              :max="goodsDetail.currentInventory"
            />
            <div v-if="isMy">
              <Button class="nobtn" disabled>立即购买</Button>
              <span style="font-size: 12px">无法购买自家店铺商品</span>
            </div>
            <div v-else>
              <Button class="btn" @click="addCart()">立即购买</Button>
            </div>

            <!-- <XtxButton
              type="primary"
              :style="{ marginTop: '20px' }"
              @click="addCart()"
            >
              立即购买
            </XtxButton> -->
          </div>
        </div>

        <!-- 商品详情 -->
        <div class="goods-footer">
          <div class="goods-article">
            <!-- 商品 -->
            <GoodsTab :goods="goodsDetail" />
          </div>
        </div>
      </div>
    </div>
  </AppGoodsLayout>
</template>

<script>
// import { toRef } from 'vue'
import { useStore } from 'vuex'
import AppGoodsLayout from '@/components/AppGoodsLayout'
import GoodsImages from '@/views/goods/components/GoodsImages'
import GoodsInfo from '@/views/goods/components/GoodsInfo'
import GoodsTab from '@/views/goods/components/GoodsTab'
import VueEvent from '@/model/event'
export default {
  name: 'GoodsDetailPage',
  components: {
    GoodsTab,
    GoodsInfo,
    GoodsImages,
    AppGoodsLayout,
  },
  data () {
    return {
      testData: [
        {
          name: '品名',
          value: 'SN74LVC1G66DCKR',
        },
        {
          name: '仓库',
          value: '上海',
        },
        {
          name: '服务',
          value: '自提/快递',
        },
        {
          name: '交货地',
          value: '国内交货（1-3工作日）',
        },
        {
          name: '支付方式',
          value: '平台支付',
        },
        {
          name: '交易模式',
          value: '全款支付',
        },
        {
          name: '全款支付',
          value: '2.00吨 抄码',
        },
      ],
      listData: [],
      goodsDetail: {},
      merchantStore: {},
      data: {},
      img: '',
      count: 1,
      isMy: false, // 是否是买自己发布的
    }
  },

  provide () {
    return {
      merchantStore: this.merchantStore,
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.buyMitt()
    this.currentMsCodeMitt()
  },
  methods: {
    init () {
      this.$store
        .dispatch('ProductDetail', this.$route.params.id)
        .then((response) => {
          this.data = response.result
          this.img = response.result.imgs
          this.goodsDetail = response.result.merchantStoreProduct
          this.merchantStore = this.data.merchantStore
        })
        .catch(() => {})
    },
    buyMitt () {
      VueEvent.on('buyMitt', (value) => {
        this.count = value
      })
    },
    addCart () {
      if (this.login) {
				if (this.login.status < 4) {
        this.$Notice.info({
          title: '账号未认证',
          desc: '请先去个人中心进行商家认证',
        })
				return
      }

        this.$router.push('/qlcheckout/submit').then(() => {
          this.data.count = this.count
          // 我的商品 筛选栏选项
          VueEvent.emit('qlCheckoutMitt', this.data)
        })
      } else {
        this.$router.push('/login').then(() => {})
      }
    },
    onBack () {
      this.$router.go(-1)
    },
    currentMsCodeMitt () {
      VueEvent.on('currentMsCodeMitt', (value) => {
        value === this.login.msCode ? (this.isMy = true) : (this.isMy = false)
      })
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
  setup () {
    const store = useStore()
    const login = store.state.login.userInfo
    return {
      login,
    }
  },
}
</script>

<style scoped lang="less">
.goods-info {
  min-height: 600px;
  background: #fff;
  display: flex;
  .media {
    width: 580px;
    height: 600px;
    padding: 30px 50px;
  }
  .spec {
    flex: 1;
    padding: 30px 30px 30px 0;
  }
}
.goods-footer {
  display: flex;
  margin-top: 20px;
  .goods-article {
    width: 940px;
    margin-right: 20px;
  }
  .goods-aside {
    width: 280px;
    min-height: 1000px;
  }
}
// .goods-tabs {
//   min-height: 300px;
//   background: #fff;
// }
.goods-warn {
  min-height: 600px;
  background: #fff;
  margin-top: 20px;
}
.attrs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  li {
    display: flex;
    margin-bottom: 10px;
    margin-left: 5px;
    width: 40%;
    .dt {
      width: 100px;
      color: #999;
    }
    .dd {
      flex: 1;
      color: #666;
    }
  }
}
.btn {
  // display: block;
  width: 140px;
  background: #d12b23;
  height: 50px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cfcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nobtn {
  // display: block;
  width: 140px;
  background: #cfcdcd;
  height: 50px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cfcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
